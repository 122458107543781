body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
}

header{
  /*padding: 5px;*/
  display: flex;
  background-color: #198b00;
  color: white;
  align-items: center;
}

header a{
  color: inherit;
  text-decoration: none;
  margin-left: 40px;
}

header a:hover{
  color: #bdb9b9;
}

main{
  padding: 20px;
  margin: auto;
  max-width: 1000px;
}

.select{
  color: #3b3b3b;
}

/*tbody th {*/
/*  text-align: left;*/
/*  vertical-align: top;*/
/*}*/


/*tbody td{*/
/*  vertical-align: top;*/
/*}*/

/*tbody th .company{*/
/*  width: 100px;*/
/*  font-size: .8em;*/
/*  white-space: nowrap;*/
/*  text-overflow: ellipsis;*/
/*  overflow: hidden;*/
/*}*/

/*tbody th .project{*/
/*  width: 150px;*/
/*  white-space: nowrap;*/
/*  text-overflow: ellipsis;*/
/*  overflow: hidden;*/
/*}*/

/*tbody td button {*/
/*  flex: 1;*/
/*  width: 100%;*/
/*  height: 30px;*/
/*  min-height: 30px;*/
/*  overflow: hidden;*/
/*  text-align: left;*/
/*  word-break: break-word;*/
/*  !*white-space: nowrap;*!*/
/*  text-overflow: ellipsis;*/
/*}*/


/*Loading Script */

.lds-ripple {
  margin: auto;
  text-align: center;
  display: inline-block;
  position: relative;
  width: 150px;
  height: 150px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #145812;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 75px;
    left: 75px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 150px;
    height: 150px;
    opacity: 0;
  }
}
